import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PolicyData, PolicyIds } from '../interfaces/policy.interface';
import { constants } from './../../@shared/constants';
import { PolicyResponse, CanopyPolicyResponse } from '@archinsurance-da/arch-portal-tools';

@Injectable({
  providedIn: 'root',
})
export class PolicyService {
  constructor(private http: HttpClient) {}

  // getPolicy(policyId: string): Observable<PolicyData> {
  //   const payload = {
  //     policyContext: {
  //       policy: {
  //         actionCode: 'getPolicy',
  //         productTypeCode: 'PW',
  //         policyId: policyId,
  //         transactionId: '1',
  //         versionId: '1',
  //         optionNo: '1',
  //       },
  //     },
  //   };

  //   return this.http.post<any>(environment.billingPortalApiHost + constants.getPolicyEndPoint, payload).pipe(
  //     map((policy: PolicyData) => {
  //       return policy;
  //     })
  //   );
  // }

  getPdfDetails(policyId: any, product: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    headers = headers.append('Accept', '*/*');
    product = constants.product_mapping[product];
    headers = headers.append('product', product);
    headers = headers.append('lob', constants.lob);
    return this.http
      .get(environment.billingPortalApiHost + constants.getCOIDetails + `${policyId}`, {
        headers,
        responseType: 'blob',
      })
      .pipe(
        map((uidResult) => {
          return uidResult;
        })
      );
  }

  getPolicy(policyId: string, product: string) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    headers = headers.append('Accept', 'application/json');
    product = constants.product_mapping[product];
    headers = headers.append('product', product);
    headers = headers.append('insuredCall', 'true');

    return this.http
      .get<any>(environment.billingPortalApiHost + constants.getPolicyEndPoint + `/${policyId}`, { headers })
      .pipe(
        map((policy: any) => {
          let policyData;
          if (product == 'essentialpl') {
            policyData = new PolicyResponse().map(policy.policy);
          } else if (product == 'canopy') {
            policyData = new CanopyPolicyResponse().map(policy.policy);
          }

          console.log(policyData);
          return policyData;
        })
      );
  }
  getUserPolicies(email: string): Observable<PolicyIds[]> {
    return this.http.get<any>(environment.billingPortalApiHost + constants.policiesEndpoint + email).pipe(
      map((policies: PolicyIds[]) => {
        return policies;
      })
    );
  }

  private plTemplateCurrent = {
    //use for insured ater paid.
    WhatIfTransactionSchedule: {
      RequestHeader: { SourceSystemRequestNo: '434121381251' },
      Transaction: {
        BillType: 'DIRECT',
        BrokerNo: '43274',
        InvoicingCurrency: 'USD',
        PolicyEffectiveDate: '2021-10-26',
        PolicyExpirationDate: '2022-10-26',
        PolicyNo: '',
        ScheduleAsOfDate: '2017-01-05+05:30',
        TransactionCurrency: 'USD',
        TransactionEffectiveDate: '2021-10-27',
        WhatIfTransaction: 'CURRENT_SCHEDULE',
      },
    },
  };

  getPaymentWhatIfPayLoad(policyNumber: string, effDate: string, expDate: string) {
    let newPl = JSON.parse(JSON.stringify(this.plTemplateCurrent));

    try {
      effDate = new Date(effDate).toISOString().split('T').shift();
      expDate = new Date(expDate).toISOString().split('T').shift();
    } catch (err) {
      console.warn('Unable to transform dates for WhatIf paymentSchedule', err);
    }

    newPl.WhatIfTransactionSchedule.Transaction.PolicyNo = policyNumber;
    newPl.WhatIfTransactionSchedule.Transaction.PolicyEffectiveDate = effDate;
    newPl.WhatIfTransactionSchedule.Transaction.PolicyExpirationDate = expDate;
    newPl.WhatIfTransactionSchedule.Transaction.ScheduleAsOfDate = effDate;
    newPl.WhatIfTransactionSchedule.Transaction.TransactionEffectiveDate = effDate;

    return newPl;
  }

  getPaymentSchedule(policyData: PolicyData): Observable<any> {
    const payload = this.getPaymentWhatIfPayLoad(
      policyData.policy.polNbr,
      policyData.policy.policyEffectiveDate,
      policyData.policy.policyExpirationDate
    );

    return this.http.post<any>(environment.billingPortalApiHost + constants.paymentScheduleEndpoint, payload).pipe(
      map((scheduleResult) => {
        return scheduleResult;
        // return this.mapMajescoScheduleToPaymentSchedule(scheduleResult);
      })
    );
  }

  getBillingInquiry(policyData: PolicyData) {
    const payload = {
      policyId: policyData.policy.policyId,
      policyNo: policyData.policy.polNbr,
      policyEffDate: policyData.policy.policyEffectiveDate,
      policyExpDate: policyData.policy.policyExpirationDate,
    };
    return this.http.post<any>(environment.billingPortalApiHost + constants.billingInquiry, payload).pipe(
      map((inquiry) => {
        return inquiry;
      })
    );
  }

  downloadPolicy(policyData: PolicyData): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    // headers = headers.append('lob', '');
    // headers = headers.append('product', 'bop');
    headers = headers.append('Accept', 'application/pdf');

    policyData.policy.actionCode = 'generatePolicy';

    let product = 'essentialpl';
    if (
      policyData.referenceData.product.productOffering &&
      policyData.referenceData.product.productOffering == 'MSPPL'
    ) {
      product = 'essentialpl';
    } else {
      product = 'canopy';
    }

    const payload = {
      product: product,
      policyId: policyData.policy.policyId,
    };

    return this.http
      .post(environment.billingPortalApiHost + constants.printPolicyEndpoint, payload, {
        headers,
        responseType: 'blob',
      })
      .pipe(
        map((uidResult) => {
          return uidResult;
          // return this.mapMajescoScheduleToPaymentSchedule(scheduleResult);
        })
      );
  }
}
