<div fxLayout="column" fxFill>
  <apt-content-box [marginTop]="0">
    <apt-h2>Hello {{ userProfile ? userProfile.given_name : '' }}</apt-h2>

    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between">
      <p>Below are your coverage summary and options to update your billing information.</p>

      <apt-button
        [primary]="true"
        *ngIf="agentCd == '43274'"
        (click)="downloadCoiPdf()"
        [loading]="downloading"
        [loadingExpectedTime]="6000"
      >
        {{ downloading ? 'Downloading Certificate Of Insurance' : 'Download Certificate Of Insurance' }}
      </apt-button>
    </div>
  </apt-content-box>

  <div fxHide.gt-xs>
    <apt-simple-select [aptSelect]="policySelect" style="padding: 0px 0px">
      <select
        #policySelect
        (change)="onChgPolicy($event)"
        style="border: white; background-color: #f2f2f2; display: inline-flex; flex-direction: row; width: 100%"
      >
        <option *ngFor="let policy of policies" [value]="'policy/' + prodmap[policy.product] + '/' + policy.policyId">
          Policy #{{ policy.policyNbr }}
        </option>
      </select>
    </apt-simple-select>

    <!-- <fa-icon [icon]="menuIcon" `(click)="toggleMenu()"></fa-icon>
    <div *ngIf="menuOpen">
      <div *ngFor="let policy of policies" [routerLink]="['policy/' + prodmap[policy.product] + '/' + policy.policyId]">Policy #{{ policy.policyNbr }}
      </div>
    </div> -->
  </div>

  <apt-content-box fxFlex class="policy-menu-containter">
    <apt-vertical-menu-router
      *ngIf="fetchCount > 1 && (policies?.length || bonds?.length)"
      class="apt-negate-margin"
      [showMenu]="!isMobile"
    >
      <apt-vertical-menu>
        <apt-vertical-menu-item
          *ngFor="let policy of policies"
          [routerLink]="['policy/' + prodmap[policy.product] + '/' + policy.policyId]"
          [selected]="policyId === policy.policyId"
          label="Policy #{{ policy.policyNbr }}"
        ></apt-vertical-menu-item>

        <apt-vertical-menu-item
          *ngFor="let bond of bonds"
          [routerLink]="['bond/' + bond.bondNumber]"
          [selected]="bondId === bond.bondNumber"
          label="Bond #{{ bond.bondNumber }}"
        ></apt-vertical-menu-item>
      </apt-vertical-menu>
    </apt-vertical-menu-router>

    <div *ngIf="fetchCount < 2">
      <apt-state-block state="info" title="Checking for policies and bonds..."></apt-state-block>
    </div>

    <div *ngIf="fetchCount > 1 && !policies?.length && !bonds?.length">
      <apt-state-block state="error" title="You do not yet have any policies or bonds."></apt-state-block>
    </div>
  </apt-content-box>
</div>
