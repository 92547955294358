import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { constants } from '@app/@shared/constants';
import { PolicyData } from '@app/@shared/interfaces/policy.interface';
import { PolicyService } from '@app/@shared/services/policy.service';
import { IconDefinition, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

interface PolicyStore {
  paymentSchedule?: any;
  [policyId: string]: PolicyData;
}

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss'],
})
export class PolicyComponent implements OnInit {
  stateIcon: IconDefinition = faExclamationCircle;
  policyId: string;
  product: string;
  policyData: any; //PolicyData;
  policyDates: any;
  policyStore: any = {}; //PolicyStore = {};
  addressString: string;
  downloading: boolean = false;
  policyReady: boolean = false;

  constructor(private route: ActivatedRoute, private router: Router, private policyService: PolicyService) {
    this.route.paramMap.subscribe((routeParams) => {
      // const routeParams = this.route.snapshot.paramMap;
      this.policyId = routeParams.get('policyid');
      this.product = routeParams.get('product');

      if (this.policyStore && this.policyStore.hasOwnProperty(this.policyId)) {
        this.policyData = this.policyStore[this.policyId];
        this.setGeneratedData();
        this.policyReady = true;
      } else {
        this.policyData = null;

        this.policyService.getPolicy(this.policyId, this.product).subscribe((policyData) => {
          console.log('policyData', policyData);
          this.policyData = policyData;

          this.policyStore[this.policyData.policy.policyId] = policyData;
          this.getPolicyBillingInquiry();
          this.setGeneratedData();
        });
      }
    });
  }

  ngOnInit(): void {}

  getPolicyBillingInquiry() {
    this.policyService.getPaymentSchedule(this.policyData).subscribe((data) => {
      // console.log('schedule installments', data);

      this.policyReady = true;

      let installments = {
        paid: [] as any[],
        upcoming: [] as any[],
      };

      if (
        data?.responseTransactionSchedule?.TransactionResponse?.TransactionDetail[0]
          ?.InstallmentScheduleSummarybyDueDate
      ) {
        let paymentSummary =
          data.responseTransactionSchedule.TransactionResponse.TransactionDetail[0]
            ?.InstallmentScheduleSummarybyDueDate;

        for (let i = 0; i < paymentSummary.length; i++) {
          const payment = paymentSummary[i];
          // console.log('payment', payment);
          for (let n = 0; n < payment.TransactionReceivableSummary.length; n++) {
            const installment = payment.TransactionReceivableSummary[n];
            let billDate = (
              installment.InstallmentNo === '0' ? installment.PolicyEffectiveDate : payment.BillDueDate
            ).split('-'); //this first installment is always bundled with 2, the first is paid on the day we issue (not the due date as specified here, so use the policy transactionEffectiveDate)
            billDate.pop(); //remove the last item which is a time (causes date parsing to fail)
            installment.BillDueDateObj = new Date(billDate);

            if (installment.Balance > 0) {
              installments.upcoming.push(installment);
            } else {
              installments.paid.push(installment);
            }
          }
        }

        //Sort the array by sequence
        installments.upcoming.sort((a, b) => {
          const aNo = parseInt(a.InstallmentNo);
          const bNo = parseInt(b.InstallmentNo);
          return aNo - bNo;
        });
        installments.paid.sort((a, b) => {
          const aNo = parseInt(a.InstallmentNo);
          const bNo = parseInt(b.InstallmentNo);
          return aNo - bNo;
        });

        //in HTML loop installments

        this.policyData.paymentSchedule = installments;
        this.policyStore[this.policyData.policy.policyId] = this.policyData;

        // console.log('installments', installments);

        // this.policyService.getBillingInquiry(this.policyData).subscribe((inquiry) => {
        //   // console.log(
        //   //   'inquiry installments',
        //   //   inquiry.BillingInquiryOutput.BillingInquirySummary[0].BillingInquiryInstallments
        //   // );
        //   // console.log(
        //   //   'inquiry BillingInquiryTransactions',
        //   //   inquiry.BillingInquiryOutput.BillingInquirySummary[0].BillingInquiryTransactions
        //   // );
        //   this.policyData.billingInquiry = inquiry;
        //   this.policyStore[this.policyData.policy.policyId] = this.policyData;

        //   this.policyReady = true;

        // });
      }
    });
  }

  setGeneratedData() {
    let policyEffDate = new Date(this.policyData.policy.policyEffectiveDate);
    console.log(this.policyData.policy.policyEffectiveDate);
    let policyExpDate = new Date(this.policyData.policy.policyExpirationDate);
    let policyRetroDate = this.policyData.policy.retroactiveDate
      ? new Date(this.policyData.policy.retroactiveDate)
      : undefined;
    this.policyDates = {
      effectiveDate: policyEffDate.toISOString().split('T').shift(),
      expirationDate: policyExpDate.toISOString().split('T').shift(),
      retroactiveDate: policyRetroDate ? policyRetroDate.toISOString().split('T').shift() : '',
    };

    // this.policyStore = {
    //   total: parseFloat(this.policyData.policy.policyITDPremium),
    //   dueToday: parseFloat(this.policyData.policy.policyITDPremium),
    //   effectiveDate: policyEffDate.toISOString().split('T').shift(),
    //   expirationDate: policyExpDate.toISOString().split('T').shift(),
    //   retroactiveDate: policyRetroDate ? policyRetroDate.toISOString().split('T').shift() : '',
    //   paymentProposal: this.policyData?.policy?.paymentPlanCd ? this.policyData.policy.paymentPlanCd : '1PAY',
    //   policyData: this.policyData,
    //   product: product,
    // };
    const addressDto = this.policyData.policy.addressDto;
    this.addressString = `${addressDto.insuredAddressStreetLine1Txt}, ${addressDto.insuredAddressCityTxt}, ${addressDto.insuredAddressStateCd} ${addressDto.insuredAddressPostalCd}`;
  }

  downloadPolicy() {
    return () => {
      if (!this.downloading) {
        this.downloading = true;
        this.policyService.downloadPolicy(this.policyData).subscribe((resultBytes) => {
          var blob = new Blob([resultBytes], { type: 'application/pdf' });
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `arch-policy-${this.policyData.policy.policyId}.pdf`;
          link.click();

          this.downloading = false;
        });
      }
    };
  }

  updateCreditCard() {
    if (this.product == 'ea') {
      return () => {
        this.router.navigate(['payment/check/policy/' + this.product + '/' + this.policyId]);
      };
    } else {
      return () => {
        this.router.navigate(['payment/update-card/policy/' + this.product + '/' + this.policyId]);
      };
    }
  }
}
