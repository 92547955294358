<div *ngIf="!policyReady">
  <apt-state-block state="info" title="Loading policy..."></apt-state-block>
</div>

<div fxLayout="column" *ngIf="policyReady" fxFill class="policy-container">
  <div class="apt-card-grid" fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start stretch" fxLayoutGap="20px">
    <apt-card class="test-card" fxFlex [footerAction]="downloadPolicy()" [footerLoadingExpectedTime]="9000">
      <ng-container header> Policy Status: #{{ policyData?.policy?.polNbr }} </ng-container>
      <ng-container body>
        <apt-state-block
          *ngIf="policyData && policyData.policy.quoteStatus != 'CAN'"
          state="success"
          title="Account is Active"
        ></apt-state-block>
        <apt-state-block
          *ngIf="policyData && policyData.policy.quoteStatus == 'CAN'"
          state="info"
          title="Account is Cancelled"
        ></apt-state-block>
      </ng-container>
      <div footer>{{ downloading ? 'Downloading...' : 'Download Policy' }}</div>
    </apt-card>

    <apt-card class="test-card" fxFlex="50%" [bodyPadding]="false" bodyMaxHeight="250px" [showFooter]="false">
      <ng-container header> Billing Schedule </ng-container>
      <ng-container body>
        <apt-table [columnsAlign]="['', 'center', 'right']" [nested]="true">
          <apt-tr rowType="gray">
            <apt-tc>Date</apt-tc>
            <apt-tc>Amount</apt-tc>
            <apt-tc>Settled</apt-tc>
          </apt-tr>

          <apt-tr *ngFor="let installment of policyData?.paymentSchedule?.paid">
            <apt-tc>{{ installment.BillDueDateObj | date: 'MM/dd/yyyy' }}</apt-tc>
            <apt-tc>{{ installment.ReceivableAmount | currency }}</apt-tc>
            <apt-tc><apt-inform state="success"></apt-inform></apt-tc>
          </apt-tr>

          <!-- <apt-tr rowType="gray">
            <apt-tc>Upcoming</apt-tc>
            <apt-tc></apt-tc>
            <apt-tc></apt-tc>
          </apt-tr> -->

          <apt-tr *ngFor="let installment of policyData?.paymentSchedule?.upcoming">
            <apt-tc>{{ installment.BillDueDateObj | date: 'MM/dd/yyyy' }}</apt-tc>
            <apt-tc>{{ installment.ReceivableAmount | currency }}</apt-tc>
            <apt-tc></apt-tc>
          </apt-tr>
        </apt-table>
      </ng-container>
      <ng-container footer></ng-container>
    </apt-card>

    <apt-card
      class="test-card"
      fxFlex
      [footerAction]="updateCreditCard()"
      *ngIf="policyData && policyData.policy.quoteStatus != 'CAN'"
    >
      <ng-container header *ngIf="product == 'pl'; else elsecanopy"> Manage Credit Card </ng-container>

      <ng-template #elsecanopy> Manage Payment Method </ng-template>
      <ng-container body *ngIf="product == 'pl'; else elsecanopybody">
        Your card is securely stored.<br /><br />
        Click below to update your card on file.
      </ng-container>

      <ng-template #elsecanopybody>
        Your payment method is securely stored.<br /><br />
        Click below to update your payment method on file.
      </ng-template>
      <ng-container footer *ngIf="product == 'pl'; else elsecanopyfooter"> Edit Credit Card </ng-container>

      <ng-template #elsecanopyfooter> Edit Payment Method </ng-template>
    </apt-card>
  </div>

  <div fxFlex class="policy-info">
    <apt-h2 underline>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="70">Insured Information</div>
      </div>
    </apt-h2>

    <div fxLayout="column" fxLayout.gt-sm="row" class="info-row">
      <div fxFlex fxLayout="column" fxLayoutAlign="start start">
        <apt-text-label label="Insured Name">
          <apt-text-waiter [text]="policyData?.policy?.addressDto?.insuredName"></apt-text-waiter>
        </apt-text-label>
      </div>
      <div fxFlex fxLayout="column" fxLayoutAlign="start start">
        <apt-text-label label="Address">
          <apt-text-waiter [text]="addressString"></apt-text-waiter>
        </apt-text-label>
      </div>
    </div>

    <apt-h2 underline>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="70">Contact Information</div>
      </div>
    </apt-h2>

    <div fxLayout="row" class="info-row">
      <div fxFlex fxLayout="column" fxLayoutAlign="start start">
        <apt-text-label label="Name">
          <apt-text-waiter [text]="policyData?.policy?.insuredContactPerson"></apt-text-waiter>
        </apt-text-label>
      </div>

      <!-- <div fxFlex fxLayout="column" fxLayoutAlign="start start">
            <apt-text-label label="Last Name">
                <apt-text-waiter [text]="policyData?.policy?.insuredContactLastName"></apt-text-waiter>
            </apt-text-label>
        </div> -->
    </div>

    <div fxLayout="row" class="info-row">
      <div fxFlex fxLayout="column" fxLayoutAlign="start start">
        <apt-text-label label="Email Address">
          <apt-text-waiter [text]="policyData?.policy?.insuredEmailTxt"></apt-text-waiter>
        </apt-text-label>
      </div>

      <div fxFlex fxLayout="column" fxLayoutAlign="start start">
        <apt-text-label label="Phone Number">
          <apt-text-waiter [text]="policyData?.policy?.insuredPhoneNo"></apt-text-waiter>
        </apt-text-label>
      </div>
    </div>

    <apt-h2 underline>Information</apt-h2>

    <div fxLayout="row" class="info-row">
      <div fxFlex fxLayout="column" fxLayoutAlign="center start">
        <apt-text-label label="Class of Business">
          <apt-text-waiter [text]="policyData?.referenceData.classOfBusiness.insuredClassofBusinessName">
          </apt-text-waiter>
        </apt-text-label>
      </div>
    </div>

    <div fxLayout="row" class="info-row">
      <div fxFlex fxLayout="column" fxLayoutAlign="center start">
        <apt-text-label label="Professional Liability Definition">
          <apt-text-waiter [text]="policyData?.referenceData.classOfBusiness.insuredClassofBusinessDesc">
            <!-- otherInsuredClassofBusinessDesc -->
          </apt-text-waiter>
        </apt-text-label>
      </div>
    </div>

    <div fxLayout="row" class="info-row">
      <div fxFlex fxLayout="column" fxLayoutAlign="center start">
        <apt-text-label label="Effective Date">
          <apt-text-waiter [text]="policyDates.effectiveDate | date: 'MM/dd/yyyy'"></apt-text-waiter>
        </apt-text-label>
      </div>

      <div fxFlex fxLayout="column" fxLayoutAlign="center start">
        <apt-text-label label="Expiration Date">
          <apt-text-waiter [text]="policyDates.expirationDate | date: 'MM/dd/yyyy'"></apt-text-waiter>
        </apt-text-label>
      </div>

      <div fxFlex fxLayout="column" fxLayoutAlign="center start" *ngIf="product != 'ea'">
        <apt-text-label label="Retroactive Date">
          <apt-text-waiter [text]="policyDates.retroactiveDate | date: 'MM/dd/yyyy'"></apt-text-waiter>
        </apt-text-label>
      </div>
    </div>

    <apt-h2 underline>Limits/Retentions</apt-h2>

    <div fxLayout="row" class="info-row">
      <div fxFlex fxLayout="column" fxLayoutAlign="center start">
        <apt-text-label label="Each Claim Limit">
          <apt-text-waiter [text]="policyData?.policy.policyLimit | currency"></apt-text-waiter>
        </apt-text-label>
      </div>

      <div fxFlex fxLayout="column" fxLayoutAlign="center start">
        <apt-text-label label="Aggregate Limit">
          <apt-text-waiter [text]="policyData?.policy.limitAggregate | currency"></apt-text-waiter>
        </apt-text-label>
      </div>

      <div fxFlex fxLayout="column" fxLayoutAlign="center start" *ngIf="product != 'ea'">
        <apt-text-label label="Retention">
          <apt-text-waiter
            [text]="policyData?.policy?.quoteCoverageList?.quoteCoverageList[0]?.deductibleAmount | currency"
          >
          </apt-text-waiter>
        </apt-text-label>
      </div>
    </div>
  </div>
</div>
